import { render, staticRenderFns } from "./ContactTemp.vue?vue&type=template&id=fa12dbb8&scoped=true&"
import script from "./ContactTemp.vue?vue&type=script&lang=js&"
export * from "./ContactTemp.vue?vue&type=script&lang=js&"
import style0 from "./ContactTemp.vue?vue&type=style&index=0&id=fa12dbb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa12dbb8",
  null
  
)

export default component.exports